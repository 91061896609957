<template>
  <ContentWrapper :fullHeight="true">
    <template v-slot:title>Contract Specification</template>
    <Container class="contract-wrapper">
      <div class="contract-options">
        <div class="exchange">
          <v-select v-model="selectedExchange" :options="exchanges" placeholder="Exchange"></v-select>
        </div>
        <div class="product">
            <v-select v-model="selectedProduct"  :options="products" placeholder="Product"></v-select>
        </div>
        <div class="contract-options-actions">
            <base-button :disabled="!(selectedExchange && selectedProduct)" mode="dark" @click="$router.push({name: 'EditContract', query: contractSpecs})"><font-awesome-icon icon="edit" /> Edit</base-button>
            <base-button  @click="$router.push({name: 'CreateContract'})"><font-awesome-icon icon="plus-circle" /> New</base-button>
        </div>
      </div>
      <div class="no-table" v-if="getTableData.length === 0">Please select the product and exchanges</div>
      <div v-else>
        <TableRow :isContract="true" :tableDetails="getTableData" style="marginTop: 2rem"/>
        <div class="source-url" v-if="!!contractSpecs[0].source_url && !!contractSpecs[0].source_title">     
          <em>Source:</em><a class="link" target="_blank" :href="contractSpecs[0].source_url"> {{contractSpecs[0].source_title}}</a>
        </div>
      </div>
    </Container>
  </ContentWrapper>
</template>

<script>
import TableRow from '@/components/TableRow';

import { products, exchanges } from "@/services/api/others.js"
import { contracts } from "@/services/api/contracts.js"

export default {
  components:{
    TableRow
  },
    watch:{
    selectedExchange(val){
      this.getProducts(val)
      if(val && this.selectedProduct){
        this.getContract()
      }
    },
    selectedProduct(val){
      if(val && this.selectedExchange){
        this.getContract()
      }
    }
  },
  computed:{
    getTableData(){
      let contract = [];
      if(this.contractSpecs.length > 0){
        for (let [key, value] of Object.entries(this.contractSpecs[0])){
        if(key === 'multiplier' || 
        key === 'initial_margin_val' || 
        key === 'maintenance_margin_val' || 
        key === 'margin_requirements' || 
        key === 'source_title' ||
        key === 'source_url' ||
        key === 'id'){
          continue;
        } else if(key === 'mini_price_fluctuation'){
          contract.push({title: 'MIN PRICE FLUCTUATION' , value})
        }else {
          contract.push({title: this.getTitle(key) , value})
        }
      }
      }
      return contract
    },
  },
  data(){
    return{
      exchanges: [],
      products: [],
      selectedExchange: "",
      selectedProduct: "",
      contractSpecs: [],
    }
  },
  methods:{
    getTitle(title){
      return title.replace(/_/g, ' ' );
    },
    async getContract(){
      let req = await contracts(this.selectedExchange, this.selectedProduct)
      this.contractSpecs = req.data.response.contractSpecs
    },
    async getExchange(){
      let req = await exchanges();
      this.exchanges = req.data.response.exchanges;
    },
    async getProducts(exchange = ""){
      let req = await products(exchange)
      this.products = req.data.response.products
    }
  },
    async mounted(){
      this.getExchange();
  }
}
</script> 

<style lang="scss" scoped>
  p{
    color: $gray;
  }

  /deep/ .table-options-row{
    display: grid;
    grid-template-columns: minmax(20%,250px) 1fr;



    @media screen and (max-width:450px){
      grid-template-columns: minmax(20%,150px) 1fr;


      & > *{
        padding: 0.2rem;
      }


      .table-description{
        margin-left: 0;
      }
    }
  }

  .contract-options{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include respond(tab-port){
      flex-direction: column;
      gap: 2em;
    }
    .exchange{
      width: 33%;

      @include respond(tab-port){
        width: 100%;
      }
    }

    .product{
      width: 33%;

      @include respond(tab-port){
        width: 100%;
      }
    }

    &-actions{
      align-self: flex-start;
      flex-shrink: 0;
      width: 30%;
      display: flex;
      justify-content: space-between;
      @include respond(tab-port){
        width: 100%;
        gap: .5em;
      }
    }
  }
  
  .no-table{
    margin-top: 2rem;
    text-align: center;
  }

  .source-url{
    margin-top: 1rem;
    text-align: end;

    @include respond(phone){
       margin: 1rem;
    } 
  }
  .link{
    color: #006ae7;
  }
  @include respond(phone){
    .contract-wrapper{
      height: calc(100vh - 60px - 58px);
    }
  }
</style>